import { useParams, useLocation } from 'react-router-dom';
import { apiClient } from '@services/api';
import { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { format, isValid } from 'date-fns';
import Alert from '@material-ui/lab/Alert';
import ContentStep1 from './steps/ContentStep1';
import ContentStep2 from './steps/ContentStep2';
import DeleteIcon from '@material-ui/icons/Delete';


const EditCombo = (props) => {
    const location = useLocation()
    const quantityList = location.state?.cupos !== undefined ? location.state.cupos : 0
    const [combo_id, setComboId] = useState(Number);
    const [combo, setCombo] = useState({});
    const [tours, setTours] = useState([])
    const [dateAvailable, setDateAvailable] = useState({})
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false)
    const [errorMessage, setErrorMessage] = useState(null)
    const history = useHistory();
    const url = `/combos/${combo_id}`

    const getComboById = (url) => {
        if (combo_id != 0) {
            apiClient.httpInstance.get(url).then(async response => {
                if (response.status === 200) {
                    setCombo(response.data);
                    setLoading(true)
                    let today = new Date();
                    const tours = response.data.tours.map(tour => {
                        let comboTour = props.comboTravels.find(t => { return t.travel_id == tour.id });
                        if (comboTour) {
                            return {
                                ...tour,
                                typeTour: props.travelInformation.is_group ? 'Em grupo' : 'Passeio privado',
                                date: comboTour.tour_date ? format(comboTour.tour_date, "yyyy-MM-dd") : format(today, "yyyy-MM-dd"),
                                quantityAdult: comboTour.adults_total,
                                discountAdult: comboTour.adults_discount,
                                totalAdult: comboTour.adults_total_amount,
                                quantityKid: comboTour.kids_total,
                                discountKid: comboTour.kids_discount,
                                totalKid: comboTour.kids_total_amount
                            };
                        }
                        else {
                            return {
                                ...tour,
                                typeTour: props.travelInformation.is_group ? 'Em grupo' : 'Passeio privado',
                                date: tour.tour_date ? format(tour.tour_date, "yyyy-MM-dd") : format(today, "yyyy-MM-dd"),
                                quantityAdult: 0,
                                discountAdult: 0,
                                totalAdult: 0,
                                quantityKid: 0,
                                discountKid: 0,
                                totalKid: 0
                            };
                        }
                    });
                    const availableDates = await Promise.all(response.data.tours.map(async (tour) => {
                        const dates = await getDateUnvaliable(`reservations_dates/available?tour_id=${tour.id}`)
                        return { id: tour.id, dates }
                    }))

                    setDateAvailable(availableDates.reduce((acc, dates) => ({ ...acc, [dates.id]: dates.dates }), {}))
                    setTours(tours);
                }
            });
        }
    }

    const getDateUnvaliable = (url) => {
        return apiClient.httpInstance.get(url).then(response => {
            if (response.status === 200) {
                return response.data
            }
        });
    }

    const changeDate = (index, date) => {
        const value = isValid(date) === true ? format(date, "yyyy-MM-dd") : null
        let newArr = [...tours]
        newArr[index].date = value
        setTours(newArr)
        let fieldsToChange = [{ name: 'date', value: value }]
        updateReservationsValues(fieldsToChange, index);
    }

    const changeValue = (index, ev) => {
        const value = ev.target.value
        const name = ev.target.name
        let newArr = [...tours]
        newArr[index][name] = value
        setTours(newArr)
        let fieldsToChange = [{ name: name, value: value }]
        updateReservationsValues(fieldsToChange, index);
    }

    const setQuantities = (index, value, type, math) => {
        let valueQuantity = math === 'add' ? (Number(value) + 1) : (Number(value) - 1)
        let totalName = null
        let totalValue = 0

        if (type === 'quantityAdult') {
            totalName = 'totalAdult'
            totalValue = (Number(tours[index].price) * Number(valueQuantity))
        } else {
            totalName = 'totalKid'
            totalValue = (Number(tours[index].kids_price) * Number(valueQuantity))
        }

        let newArr = [...tours];
        newArr[index][type] = valueQuantity;
        newArr[index][totalName] = totalValue;
        setTours(newArr);

        let fieldsToChange = [{ name: type, value: valueQuantity }, { name: totalName, value: totalValue }];
        updateReservationsValues(fieldsToChange, index);
    }

    const isDateAvailable = (date, id) => {
        let dates = null

        if (dateAvailable[id].length > 0) {
            dateAvailable[id].map((dateT, index) => {
                if (index === 0) {
                    dates = date.toDateString() === new Date(`${dateT.cupo_date}T00:00:00`).toDateString() && dateT.total_cupos > 0;
                } else {
                    dates += date.toDateString() === new Date(`${dateT.cupo_date}T00:00:00`).toDateString() && dateT.total_cupos > 0;
                }
            })
        } else {
            dates = false;
        }
        return dates
    }

    const getCupos = (tour) => {
        return apiClient.httpInstance.get(`tours/${tour.id}`, {
            params: {
                date: tour.date
            }
        }).then(response => {
            if (response.status === 200) {
                const latestTour = {
                    ...tour,
                    availables_cupos: response.data.availables_cupos,
                    cupos: response.data.cupos
                }
                return latestTour
            }
        });
    }

    const setDisabledDate = () => {
        return tours.some(obj => obj.date === null)
    }

    const setDisabledQuantity = () => {
        return tours.some(obj => obj.quantityAdult === 0)
    }

    useEffect(() => {
        setComboId(props.travelInformation.combo_id);
        getComboById(url);
    }, [url]);


    const updateReservationsValues = (fieldsToChange, indexTour) => {
        fieldsToChange.map((f) => {

            var index = props.reservations.findIndex((r) => r.travel_id == tours[indexTour].id && r.combo_id == combo_id);
            
            var indexNewReservations = props.reservationsToAdd.findIndex((r) => r.travel_id == tours[indexTour].id && r.combo_id == combo_id);
            if (indexNewReservations != -1) {
                let propName = '';
                if (f.name == 'typeTour') {
                    props.reservationsToAdd[indexNewReservations] = {
                        ...props.reservationsToAdd[indexNewReservations],
                        ['is_group']: f.value == 'Em grupo' ? true : false,
                    }
                }
                else {
                    propName = getPropName(f.name);

                    if (propName == null) {
                        return;
                    }

                    props.reservationsToAdd[indexNewReservations] = {
                        ...props.reservationsToAdd[indexNewReservations],
                        [propName]: f.value
                    }
                }

                props.setReservationsToAdd(props.reservationsToAdd);
            }

            if (index != -1) {
                let propName = '';
                if (f.name == 'typeTour') {
                    props.reservations[index] = {
                        ...props.reservations[index],
                        ['is_group']: f.value == 'Em grupo' ? true : false,
                    }
                }
                else {
                    propName = getPropName(f.name);

                    if (propName == null) {
                        return;
                    }

                    props.reservations[index] = {
                        ...props.reservations[index],
                        [propName]: f.value
                    }
                }

                props.setReservations(props.reservations);
            }
        });
    }

    const getPropName = (eventTargetName) => {
        switch (eventTargetName) {
            case 'quantityKid':
                return 'kids_total'
            case 'quantityAdult':
                return 'adults_total'
            case 'totalAdult':
                return 'adults_total_amount'
            case 'totalKid':
                return 'kids_total_amount'
            case 'discountAdult':
                return 'adults_discount'
            case 'discountKid':
                return 'kids_discount'
            case 'date':
                return 'date'
            default:
                return null;
        }
    }


    if (loading) {
        return <>
            {error && (
                <Alert className="mb-5" variant="filled" severity="error">
                    {errorMessage}
                </Alert>
            )}
            <div className="px-7 pt-6">
                <div className="flex items-center justify-between mt-2 text-2xl text-blue">
                    <span>Orçamento - {combo.name}</span>
                    <DeleteIcon className="cursor-pointer" color="secondary" onClick={() => props.removeReservation(props.travelInformation.combo_id, true, false)}>
                    </DeleteIcon>
                </div>
                <div className="mt-4 bg-white text-blue font-poppins" style={{ borderRadius: '10px' }}>
                    <div className="p-4 text-xl text-blue font-poppins flex items-center">
                        <div className="flex-none">
                            <img src={combo.banner ? `${process.env.REACT_APP_BASE_IMAGE_URL}/${combo.banner}` : null} width="60" className="rounded-full mr-3" alt="" />
                        </div>
                    </div>
                    <ContentStep1
                        tours={tours}
                        changeDate={changeDate}
                        changeValue={changeValue}
                        disableDates={isDateAvailable}
                        comboName={combo.name}
                    />
                    <ContentStep2
                        tours={tours}
                        setQuantities={setQuantities}
                        discount={combo.discount}
                        comboName={combo.name}
                    />
                </div>
            </div>
        </>
    } else {
        return <>
        </>
    }
}

export default EditCombo;