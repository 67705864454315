import { useParams, useLocation } from 'react-router-dom';
import { apiClient } from '@services/api';
import { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import DatePicker from '@components/DatePicker';
import { format, isValid } from 'date-fns';
import ContentStep1 from './steps/ContentStep1';
import ContentStep2 from './steps/ContentStep2';
import Alert from '@material-ui/lab/Alert';
import DeleteIcon from '@material-ui/icons/Delete';

const EditTransfer = (props) => {
    const location = useLocation()
    const quantityList = location.state?.cupos !== undefined ? location.state?.cupos : 0
    const [transfer_id, setTransfer_id] = useState(Number);
    const [transfer, setTransfer] = useState({});
    const [step, setStep] = useState(1)
    const [loading, setLoading] = useState(false);
    const [reservationTransfer, setReservationTransfer] = useState({
        name: null,
        date: null,
        typeTransfer: 'Transfer In',
        timeFly: null,
        codFly: null,
        quantity: 0,
        discount: 0,
        total: 0,
        totalQuantity: 0,
    })

    const [transferValues, setTransferValues] = useState([])
    const [error, setError] = useState(false)
    const [errorMessage, setErrorMessage] = useState(null)
    const history = useHistory();
    const url = `/translados/${transfer_id}`

    const getTransferValues = () => {
        const type = reservationTransfer.typeTransfer === 'Transfer In' ? 'in' : 'out'

        apiClient.httpInstance.get(`${url}/values?type=${type}`).then(response => {
            if (response.status === 200 && response.data.data.length > 0) {
                const idx = response.data.data.length - 1
                const quantity = quantityList > response.data.data[idx].value_to ? response.data.data[idx].value_to : quantityList
                const totalQuantity = response.data.data.find((transfer, idx) => {
                    if (response.data.data.length - 1 !== idx) {
                        return Math.abs(parseInt(transfer.value_to) - parseInt(response.data.data[idx + 1].value_of)) > 1
                    } else {
                        return transfer.value_to
                    }
                })

                var today = new Date();

                setReservationTransfer({
                    date: props.travelInformation.tour_date ?  format(props.travelInformation.tour_date,"yyyy-MM-dd") : format(today, "yyyy-MM-dd"),
                    typeTransfer: props.travelInformation.transfer_type == 'in' ? 'Transfer In' : 'Transfer Out',
                    timeFly: buildHour(props.travelInformation.flight_time),
                    codFly: props.travelInformation.flight_code,
                    quantity: quantity != 0 || props.travelInformation.adults_total != 0 ? quantity > props.travelInformation.adults_total ? quantity : props.travelInformation.adults_total : 1,
                    discount: props.travelInformation.discount != null ? props.travelInformation.discount : 0,
                    total: Number(props.travelInformation.total_amount),
                    totalQuantity: totalQuantity.value_to >= props.travelInformation.cupos ? totalQuantity.value_to : props.travelInformation.cupos,
                    name: response.data.region_name,
                    banner: response.data.banner
                });
                let fieldsToChange = [
                    { name: 'date',value: props.travelInformation.tour_date ?  format(props.travelInformation.tour_date,"yyyy-MM-dd") : format(today, "yyyy-MM-dd")},
                    { name: 'typeTransfer', value: props.travelInformation.typeTransfer == 'in' ? 'Transfer In' : 'Transfer Out' },
                    { name: 'timeFly', value: buildHour(props.travelInformation.flight_time) },
                    { name: 'codFly', value: props.travelInformation.flight_code },
                    { name: 'quantity', value: quantity != 0 || props.travelInformation.adults_total != 0 ? quantity > props.travelInformation.adults_total ? quantity : props.travelInformation.adults_total : 1, },
                    { name: 'discount', value: props.travelInformation.discount != null ? props.travelInformation.discount : 0 },
                    { name: 'total', value: Number(props.travelInformation.total_amount) },
                    { name: 'totalQuantity', value: totalQuantity.value_to > props.travelInformation.cupos ? totalQuantity.value_to : props.travelInformation.cupos },
                    { name: 'name', value: response.data.region_name },
                    { name: 'banner', value: response.data.banner },
                ]
                updateReservationsValues(fieldsToChange, response.id);
                setTransferValues(response.data.data);
                setLoading(true);
            } else {
                setError(true);
                setErrorMessage("Este translado não contem esse tipo de Transfer")
                setReservationTransfer({
                    ...reservationTransfer,
                    totalQuantity: 0
                })
                setTimeout(() => {
                    setError(false)
                    setErrorMessage(null)
                }, 4000);
            }
        });
    }

    const buildHour = (timeFly) => {
        if (timeFly) {

            const now = new Date();
            return new Date(`${now.getMonth() + 1} ${now.getDate()}, ${now.getUTCFullYear()} ${timeFly}`);
        }

        return null;
    }

    const getTrasladosById = (url) => {
        if (transfer_id != 0) {
            apiClient.httpInstance.get(url).then(response => {
                if (response.status === 200) {
                    setTransfer(response.data);

                    setReservationTransfer({
                        ...reservationTransfer,
                        name: response.data.region_name,
                        banner: response.data.banner
                    });

                    getTransferValues();
                }
            });
        }
    }

    const changeDate = (date) => {
        const value = isValid(date) === true ? format(date, "yyyy-MM-dd") : null
        setReservationTransfer({
            ...reservationTransfer,
            date: value
        })
        let fieldsToChange = [{ name: 'date', value: value }];

        updateReservationsValues(fieldsToChange, transfer_id);
    }

    const setQuantities = (value, math, idx) => {
        let valueQuantity = math === 'add' ? (Number(value) + 1) : (Number(value) - 1)
        setReservationTransfer({
            ...reservationTransfer,
            quantity: valueQuantity,
        })
        let fieldsToChange = [{ name: 'quantity', value: valueQuantity }];
        updateReservationsValues(fieldsToChange, transfer_id);
    }

    const changeHour = (date) => {
        setReservationTransfer({
            ...reservationTransfer,
            timeFly: date
        });
        let flight_time = isValid(date) ? format(date, "hh:mm a") : null
        let fieldsToChange = [{ name: 'timeFly', value: flight_time }];
        updateReservationsValues(fieldsToChange, transfer_id);
    }

    const changeValue = (ev) => {
        const value = ev.target.value
        setReservationTransfer({
            ...reservationTransfer,
            [ev.target.name]: value
        });

        if (ev.target.name == 'typeTransfer') {
            getTransferValues();
        }

        let fieldsToChange = [{ name: ev.target.name, value: ev.target.value }]
        updateReservationsValues(fieldsToChange, transfer_id);
    }

    const updateReservationsValues = (fieldsToChange, id) => {
        fieldsToChange.map((f) => {
            var index = props.reservationsToAdd.findIndex((r) => r.travel_id == id);
            var indexNewReservations = props.reservationsToAdd.findIndex((r) => r.travel_id == id);

            if (indexNewReservations != -1) {
                let propName = getPropName(f.name);
                if (propName == 'transfer_type') {
                    props.reservationsToAdd[indexNewReservations] = {
                        ...props.reservationsToAdd[indexNewReservations],
                        [propName]: f.value == 'Transfer In' ? 'in' : 'out',
                    }
                }
                else {

                    if (propName == null) {
                        return;
                    }

                    props.reservationsToAdd[indexNewReservations] = {
                        ...props.reservationsToAdd[indexNewReservations],
                        [propName]: f.value
                    }
                }

                props.setReservationsToAdd(props.reservations);
            }

            if (index != -1) {
                let propName = getPropName(f.name);
                if (propName == 'transfer_type') {
                    props.reservations[index] = {
                        ...props.reservations[index],
                        [propName]: f.value == 'Transfer In' ? 'in' : 'out',
                    }
                }
                else {

                    if (propName == null) {
                        return;
                    }

                    props.reservations[index] = {
                        ...props.reservations[index],
                        [propName]: f.value
                    }
                }

                props.setReservations(props.reservations);
            }
        });
    }

    const getPropName = (eventTargetName) => {
        switch (eventTargetName) {
            case 'date':
                return 'date'
            case 'typeTransfer':
                return 'transfer_type'
            case 'timeFly':
                return 'flight_time'
            case 'codFly':
                return 'flight_code'
            case 'discount':
                return 'adults_discount'
            case 'quantity':
                return 'adults_total'
            case 'total':
                return 'adults_total_amount'
            case 'name':
                return 'travel_name'
            case 'banner':
                return 'banner'
            default:
                return null;
        }
    }

    const setDefaultValues = () => {
        let date = new Date
        date.setDate(date.getDate() + 5)
        changeDate(date)
        changeHour(date)
    }

    useEffect(() => {
        setTransfer_id(props.travelInformation.travel_id);
        getTrasladosById(url);
    }, [url]);

    if (loading) {
        return <>
            {error && (
                <Alert className="mb-5" variant="filled" severity="error">
                    {errorMessage}
                </Alert>
            )}
            <div className="px-7 pt-6">
                <div className="flex items-center justify-between mt-2 text-2xl text-blue">
                    <span>Comprar</span>
                    <DeleteIcon className="cursor-pointer" color="secondary" onClick={() => props.removeReservation(transfer_id, false, false)}>
                    </DeleteIcon>
                </div>
                <div className="mt-4 bg-white text-blue font-poppins" style={{ borderRadius: '10px' }}>
                    <div className="p-4 text-xl text-blue font-poppins flex items-center">
                        <div className="flex-1">
                            <div className="font-medium text-base">
                                {transfer.region_name}
                            </div>
                        </div>
                        <div className="flex-1 text-right">
                            <DatePicker
                                label="Data"
                                value={reservationTransfer.date !== null ? `${reservationTransfer.date} 00:00:00` : new Date()}
                                onChange={date => changeDate(date)}
                            />
                        </div>
                    </div>
                    <ContentStep1
                        {...props}
                        reservationTransfer={reservationTransfer}
                        changeValue={changeValue}
                        changeHour={changeHour}
                    />
                    <ContentStep2
                        {...props}
                        setQuantities={setQuantities}
                        transferValues={transferValues}
                        reservationTransfer={reservationTransfer}
                        changeValue={changeValue}
                    />
                </div>
            </div>

        </>
    } else {
        return <>
        </>
    }
}

export default EditTransfer;