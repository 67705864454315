import Modal from '@components/Modal';
import Button from '@components/Button';
import { useRef, useState } from 'react';
import Icon from '@material-ui/core/Icon';
import EditToursTable from '../../budgets/product/tour/EditToursTable';
import EditComboTable from '../../budgets/product/combo/EditCombosTable';
import EditTransferTable from '../../budgets/product/transfer/EditTransferTable';
const ChooseProductEditModal = (props) => {
  let ref = useRef(null);
  const [typeOfTravel, setTypeOfTravel] = useState(0);

  return <>
    <Modal open={props.open} close={props.close} style={{ width: '96%', maxWidth: typeOfTravel == 0 ? '550px' : '80%' }}>
      <div className="flex font-poppins">
        <div className="flex-auto text-center">
          <span className="mb-3 text-xl text-blue">Novo orçamento</span>
        </div>
        <div className="flex-none">
          <Icon ref={ref} className="cursor-pointer" onClick={props.close}>close</Icon>
        </div>
      </div>
      <div className="flex">
        <div className="flex-1 text-center">
          <span className="mb-3 text-xs text-gray">Escolha o tipo de produto para o seu orçamento</span>
        </div>
      </div>
      <div className="justify-center my-4 space-x-4 md:flex w-100">
        <Button
          textcolor="#fff"
          padding="5px 14px"
          margintop="0px"
          width="100px"
          onClick={() => setTypeOfTravel(1)}
          customcolor={props.theme.color}
        >
          Passeios
        </Button>
        <Button
          textcolor="#fff"
          padding="5px 14px"
          margintop="0px"
          width="110px"
          onClick={() => setTypeOfTravel(2)}
          customcolor={props.theme.color}
        >
          Traslados
        </Button>
        <Button
          textcolor="#fff"
          padding="5px 14px"
          margintop="0px"
          width="100px"
          onClick={() => setTypeOfTravel(3)}
          customcolor={props.theme.color}
        >
          Combos
        </Button>
      </div>
      <div className="justify-center my-4 space-x-4 md:flex w-100">
        {typeOfTravel == 1 && <EditToursTable {...props} pushBudgets={props.pushBudgets} getReservations={props.getReservations} />}
        {typeOfTravel == 2 && <EditTransferTable {...props} pushBudgets={props.pushBudgets} getReservations={props.getReservations} />}
        {typeOfTravel == 3 && <EditComboTable  {...props} pushBudgets={props.pushBudgets} reserevations={props.reserevations} getReservations={props.getReservations} />}
      </div>
    </Modal>
  </>
}

export default ChooseProductEditModal;
