import TextField from '@components/TextField';
import Button from '@components/Button';
import DatePicker from '@components/DatePicker';
import Table from '@components/Table';
import Icon from '@material-ui/core/Icon';
import { useState } from 'react';
import { format, isValid } from 'date-fns';
import { apiClient } from '@services/api';
import Alert from '@material-ui/lab/Alert';

const EditTransferTable = (props) => {
  document.title = `Lista de traslados - ${process.env.REACT_APP_NAME}`
  let [rows] = useState([]);
  const [q, setQ] = useState(null);
  const [cupos, setCupos] = useState(0);
  const [reservations, setReservations] = useState(true);
  const [date, setDate] = useState(null);
  const [isError, setError] = useState(false);


  const changeDate = (date) => {
    setDate(isValid(date) === true ? format(date, "yyyy-MM-dd") : null)
  }

  const handle = (e, row) => {
    var travels = []
    var reservations = []
    while (reservations.length == 0 || reservations == undefined || reservations == null) {
      reservations = props.getReservations();
    }

    var index = reservations.findIndex((f) => { return f.travel_id == row.id });

    if (index != -1) {
      setTimeout(() => { setError(true) }, 4000);
      setError(false);
      return;
    }

    var item = {
      id: 0,
      travel_id: row.id,
      travel_type: null,
      transfer_type: 'in',
      customer_email: "",
      cupos: row.availables_cupos,
      operational_cost: row.operational_cost,
      is_group: true,
      tour_date: null,
      adults_total: 1,
      adults_total_amount: 0,
      adults_operational_cost: row.operational_cost,
      adults_discount: row.adult_discount,
      kids_total: 0,
      kids_total_amount: 0,
      kids_operational_cost: row.kid_operational_cost,
      kids_discount: row.kid_discount_percentage,
      discount_total_amount: 0,
      combo_id: 0,
      total_amount: 0,
      commission: null,
      discount: null,
      flight_code: null,
      flight_time: null,
      fixed_commission_dolars: null,
      fixed_commission_pesos: null,
      margin_discount: null,
      mode_transfer: null,
      modality: null,
      quantity: row.quantity ? row.quantity : 0,
      status: row.status == true ? "1" : "0",
      total: 0,
      value: 0,
      value_cost: null,
      value_pesos: null,
      value_pesos_cost: null,
      value_to: null,
      value_of: null,
      pre_sale_id: 0,
      status_discount_approve: null,
      justify_discount: null,
      discount_total_sale: 0,
      price: row.price,
      travel_name: row.name,
      region_id: row.region,
      banner: row.banner,
      type: "transfer"
    }

    props.pushBudgets(item, false, false, true);
  }


  return <>
    <div className="px-7 pt-6">
      <div className="flex items-center justify-between mt-2 text-2xl text-blue">
        <span>Traslados</span>
      </div>
      <div className="p-4 mt-4 bg-white border-1" style={{ borderRadius: '10px 10px 0px 0px' }}>
        <div className="grid grid-cols-4 gap-4 items-center">
          <div>
            <TextField
              className="inputRounded"
              placeholder="Busque por um passeio"
              variant="outlined"
              type="text"
              name="q"
              value={q}
              onChange={(e) => setQ(e.target.value)}
              required
            />
          </div>
          <div>
            <DatePicker
              width="170px"
              label="Data"
              value={date !== null ? `${date} 00:00:00` : null}
              onChange={date => changeDate(date)}
            />
          </div>
          <div className="flex col-start-3 col-end-5 justify-end items-center">
            {cupos > 0 &&
              <Icon className="cursor-pointer" onClick={() => setCupos(Number(cupos) - 1)}>remove</Icon>
            }
            <TextField
              className="inputRounded mx-4"
              placeholder="Vagas"
              width="100px"
              variant="outlined"
              type="number"
              name="cupos"
              value={cupos}
              onChange={(e) => setCupos(e.target.value)}
              required
            />
            <Icon className="cursor-pointer" onClick={() => setCupos(Number(cupos) + 1)}>add</Icon>
          </div>
        </div>
      </div>
      {isError && (
        <Alert className="mb-5" variant="filled" severity="error">
          {'Já existe esse transfer no orçamento.'}
        </Alert>
      )}
      <Table
        path="/translados"
        params={{
          q, date, cupos, reservations
        }}
        onRowClick={null}
        columns={[
          {
            headerName: 'Traslados',
            headerClassName: 'super-app-theme--header',
            field: 'region_name',
            flex: 1,
          },
          {
            headerName: ' ',
            field: 'actions',
            headerAlign: 'right',
            align: 'right',
            headerClassName: 'super-app-theme--header',
            flex: 0.5,
            renderCell: (params) => {
              const row = params.row;
              const link = `traslados/${row.id}/comprar`
              return (<div className="flex items-center pr-4 mb-3">
                <Button
                  textcolor="#212121"
                  padding="6px 15px"
                  width="200"
                  variant="outlined"
                  onClick={(e) => { handle(e, row) }}
                  bgColor="transparent"
                >
                  Adicionar ao Orçamento
                </Button>
              </div>);
            }
          },
        ]}
        rows={rows}
      />
    </div>
  </>
}

export default EditTransferTable;
