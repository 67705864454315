import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableFooter from '@material-ui/core/TableFooter';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Icon from '@material-ui/core/Icon';
import NumberFormat from 'react-number-format';
import DiscountInput from '@components/DiscountInput';
import { useState } from 'react';

const useStyles = makeStyles({
  table: {
    width: '100%',
  },
  tableContainer: {
    boxShadow: 'none'
  }
});

const ContentStep2 = (props) => {
  const [error, setError] = useState('');
  const classes = useStyles();
  let operationalCost = props.tour.operational_cost;
  let operationalCostSale = props.tour.operational_cost_sale;
  let tourValue = 0;

  const calcDiscountTotal = () => {
    if (props.reservationTour.typeTour === false || props.reservationTour.typeTour === 'Passeio privado') {
      const valueAdult = (Number(tourValue) * (Number(props.reservationTour.discountAdult) / 100))
      return valueAdult;
    }
    const valueAdult = (Number(props.reservationTour.totalAdult) * (Number(props.reservationTour.discountAdult) / 100))
    const valueKid = (Number(props.reservationTour.totalKid) * (Number(props.reservationTour.discountKid) / 100))
    return valueAdult + valueKid
  }
  const calcValueTotal = () => {
    if (props.reservationTour.typeTour === false || props.reservationTour.typeTour === 'Passeio privado') {
      const subTotal = Number(tourValue)
      const valueDiscountAdult = Number(tourValue) * (Number(props.reservationTour.discountAdult) / 100)
      props.reservationTour.totalAdult = Number(tourValue)
      return (subTotal - valueDiscountAdult);
    }

    const subTotal = Number(props.reservationTour.totalAdult) + Number(props.reservationTour.totalKid)
    const valueDiscountAdult = Number(props.reservationTour.totalAdult) * (Number(props.reservationTour.discountAdult) / 100)
    const valueDiscountKid = Number(props.reservationTour.totalKid) * (Number(props.reservationTour.discountKid) / 100)
    return (subTotal - (valueDiscountAdult + valueDiscountKid));
  }

  const calcValuesReservation = (properties) => {
    let valid = true
    if (properties.reservationTour.typeTour === false || properties.reservationTour.typeTour === 'Passeio privado') {
      if (properties.tour.privateValues.length > 0) {
        properties.tour.privateValues.forEach(element => {
          if (element.min_people <= properties.reservationTour.quantityAdult && properties.reservationTour.quantityAdult <= element.max_people) {
            operationalCost = element.cost
            operationalCostSale = element.cost_sale
            tourValue = element.value
          }
        });
        if (tourValue === 0) {
          //setError('Não existe intervalo definido para esse quantitativo de lugares')
          setTimeout(() => { setError("Não existe intervalo definido para esse quantitativo de lugares") }, 500);
          return 0
        }
        setTimeout(() => { setError("") }, 500);
        return tourValue
      } else {
        return 0
      }
    }

    if (valid == true) {
      return properties.reservationTour.totalAdult
    }
  }

  const notDisableAddButton = () => {
    return (props.reservationTour.quantityAdult + props.reservationTour.quantityKid) < props.tour.availables_cupos;
  }

  const notDisableMinusAdultButton = () => {
    return props.reservationTour.quantityAdult > 0;
  }

  const notDisableMinusKidButton = () =>{
    return props.reservationTour.quantityKid > 0;
  }

  return <>
    <TableContainer className={classes.tableContainer} component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className="font-bold text-blue">Passageiros</TableCell>
            <TableCell className="font-bold text-blue">Quantidade</TableCell>
            <TableCell className="font-bold text-blue">Valor</TableCell>
            <TableCell className="font-bold text-blue">TX operacional Parceiro</TableCell>
            <TableCell className="font-bold text-blue">TX operacional Venda</TableCell>
            <TableCell className="font-bold text-blue">Desconto</TableCell>
            <TableCell className="font-bold text-right text-blue">Total</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell className="text-blue" component="th" scope="row">
              Adultos
            </TableCell>
            <TableCell className="text-blue" component="th" scope="row">

              <Icon
                className={notDisableMinusAdultButton() ? "mr-2 text-sm cursor-pointer" : "mr-2 text-sm"} style={{color:!notDisableMinusAdultButton() ? '#c9c9c9':'black'}}
                onClick={notDisableMinusAdultButton() ? () => props.setQuantities(props.reservationTour.quantityAdult, 'quantityAdult', 'remove') : () => { }}
              >
                remove
              </Icon>
              {props.reservationTour.quantityAdult}
              <Icon
                className={notDisableAddButton() ? "ml-2 text-sm cursor-pointer" : "ml-2 text-sm"} style={{color:!notDisableAddButton() ? '#c9c9c9':'black'}}
                onClick={notDisableAddButton() ? () => props.setQuantities(props.reservationTour.quantityAdult, 'quantityAdult', 'add') : () => { }}
              >
                add
              </Icon>
              <br />
              <small>{error}</small>
            </TableCell>
            <TableCell className="text-blue" component="th" scope="row">
              <NumberFormat
                value={Number(props.tour.price)}
                displayType={'text'}
                decimalSeparator={","}
                decimalScale={2}
                fixedDecimalScale={true}
                decimalPrecision={2}
                thousandSeparator={"."}
                isNumericString
                prefix={`${process.env.REACT_APP_CURRENCY} `}
                renderText={(value) => <span className="flex items-center">{value}</span>}
              />
            </TableCell>
            <TableCell className="text-blue" component="th" scope="row">
              <NumberFormat
                value={operationalCost}
                displayType={'text'}
                decimalSeparator={","}
                decimalScale={2}
                fixedDecimalScale={true}
                decimalPrecision={2}
                thousandSeparator={"."}
                isNumericString
                prefix={`${process.env.REACT_APP_CURRENCY} `}
                renderText={(value) => <span className="flex items-center">{value}</span>}
              />
            </TableCell>
            <TableCell className="text-blue" component="th" scope="row">
              <NumberFormat
                value={operationalCostSale}
                displayType={'text'}
                decimalSeparator={","}
                decimalScale={2}
                fixedDecimalScale={true}
                decimalPrecision={2}
                thousandSeparator={"."}
                isNumericString
                prefix={`${process.env.REACT_APP_CURRENCY} `}
                renderText={(value) => <span className="flex items-center">{value}</span>}
              />
            </TableCell>
            <TableCell className="text-blue" component="th" scope="row">
              <DiscountInput
                typeService="tour"
                name="discountAdult"
                value={props.reservationTour.discountAdult}
                onChange={(ev) => props.changeValue(ev)}
                discountLimit={props.tour.adult_discount_percentage}
              />
            </TableCell>
            <TableCell className="text-blue" component="th" scope="row">
              <NumberFormat
                value={props.reservationTour.typeTour === true || props.reservationTour.typeTour === "Em grupo" ? props.reservationTour.totalAdult - (props.reservationTour.totalAdult * (Number(props.reservationTour.discountAdult) / 100)) : tourValue - (tourValue * (Number(props.reservationTour.discountAdult) / 100))}
                displayType={'text'}
                decimalSeparator={","}
                decimalScale={2}
                fixedDecimalScale={true}
                decimalPrecision={2}
                thousandSeparator={"."}
                isNumericString
                prefix={`${process.env.REACT_APP_CURRENCY} `}
                renderText={(value) => <span className="flex justify-end">{value}</span>}
              />
            </TableCell>
          </TableRow>
          {props.reservationTour.typeTour === true || props.reservationTour.typeTour === "Em grupo" &&
            <TableRow>
              <TableCell className="text-blue" component="th" scope="row">
                Crianças
              </TableCell>
              <TableCell className="text-blue" component="th" scope="row">
                <Icon
                  className={notDisableMinusKidButton() ? "mr-2 text-sm cursor-pointer" : "mr-2 text-sm"} style={{color:!notDisableMinusKidButton() ? '#c9c9c9':'black'}}
                  onClick={notDisableMinusKidButton() ? () => props.setQuantities(props.reservationTour.quantityKid, 'quantityKid', 'remove') : () => { }}
                >
                  remove
                </Icon>
                {props.reservationTour.quantityKid}
                <Icon
                  className={notDisableAddButton() ? "ml-2 text-sm cursor-pointer" : "ml-2 text-sm"} style={{color:!notDisableAddButton() ? '#c9c9c9':'black'}}
                  onClick={notDisableAddButton() ? () => props.setQuantities(props.reservationTour.quantityKid, 'quantityKid', 'add') : () => { }}
                >
                  add
                </Icon>
              </TableCell>
              <TableCell className="text-blue" component="th" scope="row">
                <NumberFormat
                  value={props.tour.kids_price}
                  displayType={'text'}
                  decimalSeparator={","}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  decimalPrecision={2}
                  thousandSeparator={"."}
                  isNumericString
                  prefix={`${process.env.REACT_APP_CURRENCY} `}
                  renderText={(value) => <span className="flex items-center">{value}</span>}
                />
              </TableCell>
              <TableCell className="text-blue" component="th" scope="row">
                <NumberFormat
                  value={props.tour.kid_operational_cost}
                  displayType={'text'}
                  decimalSeparator={","}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  decimalPrecision={2}
                  thousandSeparator={"."}
                  isNumericString
                  prefix={`${process.env.REACT_APP_CURRENCY} `}
                  renderText={(value) => <span className="flex items-center">{value}</span>}
                />
              </TableCell>
              <TableCell className="text-blue" component="th" scope="row">
                <NumberFormat
                  value={props.tour.kid_operational_cost_sale}
                  displayType={'text'}
                  decimalSeparator={","}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  decimalPrecision={2}
                  thousandSeparator={"."}
                  isNumericString
                  prefix={`${process.env.REACT_APP_CURRENCY} `}
                  renderText={(value) => <span className="flex items-center">{value}</span>}
                />
              </TableCell>
              <TableCell className="text-blue" component="th" scope="row">
                <DiscountInput
                  typeService="tour"
                  name="discountKid"
                  value={props.reservationTour.discountKid}
                  onChange={(ev) => props.changeValue(ev)}
                  discountLimit={props.tour.kid_discount_percentage}
                />
              </TableCell>
              <TableCell className="text-blue" component="th" scope="row">
                <NumberFormat
                  value={props.reservationTour.totalKid - (props.reservationTour.totalKid * (Number(props.reservationTour.discountKid) / 100))}
                  displayType={'text'}
                  decimalSeparator={","}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  decimalPrecision={2}
                  thousandSeparator={"."}
                  isNumericString
                  prefix={`${process.env.REACT_APP_CURRENCY} `}
                  renderText={(value) => <span className="flex justify-end">{value}</span>}
                />
              </TableCell>
            </TableRow>
          }
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell className="text-base text-blue" component="th" scope="row">
              Subtotal
            </TableCell>
            <TableCell className="text-base text-blue" colSpan={5}>
              <NumberFormat
                value={props.reservationTour.typeTour === true || props.reservationTour.typeTour === "Em grupo" ? (Number(props.reservationTour.totalAdult) + Number(props.reservationTour.totalKid)) : (Number(tourValue))}
                displayType={'text'}
                decimalSeparator={","}
                decimalScale={2}
                fixedDecimalScale={true}
                decimalPrecision={2}
                thousandSeparator={"."}
                isNumericString
                prefix={`${process.env.REACT_APP_CURRENCY} `}
                renderText={(value) => <span className="flex justify-end">{value}</span>}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="text-base text-blue" component="th" scope="row">
              Descontos
            </TableCell>
            <TableCell className="text-base text-blue" colSpan={5}>
              <NumberFormat
                value={calcDiscountTotal()}
                displayType={'text'}
                decimalSeparator={","}
                decimalScale={2}
                fixedDecimalScale={true}
                decimalPrecision={2}
                thousandSeparator={"."}
                isNumericString
                prefix={`${process.env.REACT_APP_CURRENCY} `}
                renderText={(value) => <span className="flex justify-end">{value}</span>}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="text-lg font-medium text-blue" component="th" scope="row">
              Total
            </TableCell>
            <TableCell className="text-lg font-medium text-blue" colSpan={5}>
              <NumberFormat
                value={calcValueTotal()}
                displayType={'text'}
                decimalSeparator={","}
                decimalScale={2}
                fixedDecimalScale={true}
                decimalPrecision={2}
                thousandSeparator={"."}
                isNumericString
                prefix={`${process.env.REACT_APP_CURRENCY} `}
                renderText={(value) => <span className="flex justify-end">{value}</span>}
              />
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  </>
}

export default ContentStep2;