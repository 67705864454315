import Icon from '@material-ui/core/Icon';
import { useParams, useLocation } from 'react-router-dom';
import { apiClient } from '@services/api';
import { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import ContentStep1 from './steps/ContentStep1'
import ContentStep2 from './steps/ContentStep2'
import { format, isValid } from 'date-fns';
import Alert from '@material-ui/lab/Alert';
import DeleteIcon from '@material-ui/icons/Delete';
import de from 'date-fns/esm/locale/de/index.js';


const EditTour = (props) => {
    const location = useLocation()
    const quantityList = location.state?.cupos !== undefined ? location.state.cupos : 0
    const [tour_id, setTourId] = useState(Number);
    const [tour, setTour] = useState({});
    const [dateAvailable, setDateAvailable] = useState([])
    const [step, setStep] = useState(1)
    const [loading, setLoading] = useState(false);
    const [reservationTour, setReservationTour] = useState({
        quantityAdult: 0,
        quantityKid: 0,
        date: null,
        typeTour: 'Em grupo',
        discountAdult: 0,
        discountKid: 0,
        totalAdult: 0,
        totalKid: 0,
        total: 0
    })
    const [error, setError] = useState(false)
    const [errorMessage, setErrorMessage] = useState(null)
    const history = useHistory();
    const url = `/tours/${tour_id}`
    const urlDatesUnvaliable = `reservations_dates/available?tour_id=${tour_id}`

    const getToursById = (url) => {
        if (tour_id != 0) {
            apiClient.httpInstance.get(url).then(response => {
                if (response.status === 200) {
                    setTour({
                        ...response.data,
                        availables_cupos: response.data.availables_cupos
                    });
                    setLoading(true)
                }
            })
                .catch((error) => console.log(error));
        }
    }

    const getDateAvailable = url => {
        if (tour_id != 0) {
            apiClient.httpInstance.get(url).then(response => {
                if (response.status === 200) {
                    setDateAvailable(response.data);
                }
            })
                .catch((error) => console.log(error));
        }
    }

    const setQuantities = (value, type, math) => {
        let valueQuantity = math === 'add' ? (Number(value) + 1) : (Number(value) - 1)
        let totalName = null
        let totalValue = 0
        let typeToReservation = '';
        let totalValueName = ''
        if (type === 'quantityAdult') {
            totalName = 'totalAdult'
            totalValue = (Number(tour.price) * Number(valueQuantity))
        } else {
            typeToReservation = 'kids_total'
            totalName = 'totalKid'
            totalValue = (Number(tour.kids_price) * Number(valueQuantity))
        }

        setReservationTour({
            ...reservationTour,
            [type]: valueQuantity,
            [totalName]: totalValue
        });

        let fieldsToChange = [{ name: type, value: valueQuantity }, { name: totalName, value: totalValue }];
        updateReservationsValues(fieldsToChange);
    }

    const changeValue = (ev) => {
        setReservationTour({
            ...reservationTour,
            [ev.target.name]: ev.target.value
        });
        let fieldsToChange = [{ name: ev.target.name, value: ev.target.value }]
        updateReservationsValues(fieldsToChange);
    }

    const updateReservationsValues = (fieldsToChange) => {
        fieldsToChange.map((f) => {

            var index = props.reservations.findIndex((r) => r.travel_id == tour_id && r.combo_id == null);
            var indexNewReservations = props.reservationsToAdd.findIndex((r) => r.travel_id == tour_id && r.combo_id == null);

            if (indexNewReservations != -1) {
                let propName = '';
                if (f.name == 'typeTour') {
                    props.reservationsToAdd[indexNewReservations] = {
                        ...props.reservationsToAdd[indexNewReservations],
                        ['is_group']: f.value == 'Em grupo' ? true : false,
                        ['kids_total']: f.value == 'Em grupo' ? props.reservationsToAdd[indexNewReservations].kids_total : 0,
                        ['kids_total_amount']: f.value == 'Em grupo' ? props.reservationsToAdd[indexNewReservations].kids_total_amount : 0,
                        ['kids_discount']: f.value == 'Em grupo' ? props.reservationsToAdd[indexNewReservations].kids_discount : 0
                    }
                }
                else {
                    propName = getPropName(f.name);

                    if (propName == null) {
                        return;
                    }

                    props.reservationsToAdd[indexNewReservations] = {
                        ...props.reservationsToAdd[indexNewReservations],
                        [propName]: f.value
                    }
                }

                props.setReservationsToAdd(props.reservationsToAdd);
            }


            if (index != -1) {
                let propName = '';
                if (f.name == 'typeTour') {
                    props.reservations[index] = {
                        ...props.reservations[index],
                        ['is_group']: f.value == 'Em grupo' ? true : false,
                        ['kids_total']: f.value == 'Em grupo' ? props.reservations[index].kids_total : 0,
                        ['kids_total_amount']: f.value == 'Em grupo' ? props.reservations[index].kids_total_amount : 0,
                        ['kids_discount']: f.value == 'Em grupo' ? props.reservations[index].kids_discount : 0
                    }
                }
                else {
                    propName = getPropName(f.name);

                    if (propName == null) {
                        return;
                    }

                    props.reservations[index] = {
                        ...props.reservations[index],
                        [propName]: f.value
                    }
                }

                props.setReservations(props.reservations);
            }
        });
    }

    const getPropName = (eventTargetName) => {
        switch (eventTargetName) {
            case 'quantityKid':
                return 'kids_total'
            case 'quantityAdult':
                return 'adults_total'
            case 'totalAdult':
                return 'adults_total_amount'
            case 'totalKid':
                return 'kids_total_amount'
            case 'discountAdult':
                return 'adults_discount'
            case 'discountKid':
                return 'kids_discount'
            case 'date':
                return 'date'
            default:
                return null;
        }
    }

    const changeDate = (date) => {
        const value = isValid(date) === true ? format(date, "yyyy-MM-dd") : null
        setReservationTour({
            ...reservationTour,
            date: value
        })

        updateReservationsValues([{ name: 'date', value: value }]);

        getDate(value)
    }

    const getDate = async date => {
        if (date) {
            apiClient.httpInstance.get(url, {
                params: {
                    date: date
                }
            }).then(response => {
                if (response.status === 200) {
                    setTour(response.data)
                }
            })
                .catch((error) => console.log(error));
        }
    }

    useEffect(() => {
        setTourId(props.travelInformation.travel_id)
        getToursById(url);
        getDateAvailable(urlDatesUnvaliable);
        let today = new Date();
        setReservationTour({
            typeTour: props.travelInformation.is_group ? 'Em grupo' : 'Passeio privado',
            date: props.travelInformation.tour_date ?  format(props.travelInformation.tour_date,"yyyy-MM-dd") : format(today, "yyyy-MM-dd"),
            quantityAdult: props.travelInformation.adults_total,
            discountAdult: props.travelInformation.adults_discount,
            totalAdult: props.travelInformation.adults_total_amount,
            quantityKid: props.travelInformation.kids_total,
            discountKid: props.travelInformation.kids_discount,
            totalKid: props.travelInformation.kids_total_amount,
        });
    }, [url, urlDatesUnvaliable]);

    if (loading) {
        return <>
            {error && (
                <Alert className="mb-5" variant="filled" severity="error">
                    {errorMessage}
                </Alert>
            )}
            <div className="px-7 pt-6">
                <div className="flex items-center justify-between mt-2 text-2xl text-blue">
                    <span>Orçamento</span>
                    <DeleteIcon className="cursor-pointer" color="secondary" onClick={() => props.removeReservation(tour_id, false, true)}>
                    </DeleteIcon>
                </div>
                <div className="mt-4 bg-white text-blue font-poppins" style={{ borderRadius: '10px' }}>
                    <div className="flex p-4 text-xl text-blue font-poppins">
                        <div className="flex-none">
                            <img src={tour.banner ? `${process.env.REACT_APP_BASE_IMAGE_URL}/${tour.banner}` : null} width="60" className="mr-3 rounded-full" alt="" />
                        </div>
                        <div className="flex-auto space-y-1">
                            <div className="text-base font-medium">
                                {tour.name}
                            </div>
                            <div className="text-xs font-medium">
                                {tour.region}
                            </div>
                            <div className="flex items-center text-sm font-medium text-gray">
                                <Icon className="mr-2">group</Icon> {reservationTour.typeTour}
                            </div>
                        </div>
                    </div>
                    <ContentStep1
                        {...props}
                        typeTour={reservationTour.typeTour}
                        setTypeTour={changeValue}
                    />
                    <ContentStep2
                        {...props}
                        tour={tour}
                        setQuantities={setQuantities}
                        changeValue={changeValue}
                        reservationTour={reservationTour}
                    />
                </div>
            </div>
        </>
    }
    else {
        return <></>
    }
}

export default EditTour;