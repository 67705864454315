import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableFooter from '@material-ui/core/TableFooter';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Icon from '@material-ui/core/Icon';
import NumberFormat from 'react-number-format';
const useStyles = makeStyles({
  table: {
    width: '100%',
  },
  tableContainer: {
    boxShadow: 'none'
  }
});

const ContentStep2 = (props) => {
  const classes = useStyles();

  const calcSubtotal = () => {
    const subtotalAdult = props.tours.reduce((accumulator, currentValue) => accumulator + Number(currentValue.totalAdult), 0)
    const subtotalKid = props.tours.reduce((accumulator, currentValue) => accumulator + Number(currentValue.totalKid), 0)
    return subtotalAdult + subtotalKid
  }

  const calcDiscountTotal = () => {
    const subtotalAdult = props.tours.reduce((accumulator, currentValue) => accumulator + Number(currentValue.totalAdult), 0)
    const subtotalKid = props.tours.reduce((accumulator, currentValue) => accumulator + Number(currentValue.totalKid), 0)
    return (subtotalAdult + subtotalKid) * (Number(props.discount) / 100)
  }

  const calcValueTotal = () => {
    const subtotalAdult = props.tours.reduce((accumulator, currentValue) => accumulator + Number(currentValue.totalAdult), 0)
    const subtotalKid = props.tours.reduce((accumulator, currentValue) => accumulator + Number(currentValue.totalKid), 0)
    return (subtotalAdult + subtotalKid ) - (subtotalAdult + subtotalKid) * (Number(props.discount) / 100)
  }

  return <>
    <div className="mt-4 border-t border-solid border-lightgray">
    <TableContainer className={classes.tableContainer} component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        {props.tours.map((tour, idx) => (
          <>
            <TableHead>
              <div className="flex p-4 text-xl text-blue font-poppins">
                <div className="flex-none">
                  <img src={tour.banner ? `${process.env.REACT_APP_BASE_IMAGE_URL}/${tour.banner}` : null} width="60" className="mr-3 rounded-full" alt="" />
                </div>
                <div className="flex-auto space-y-1">
                  <div className="text-base font-medium">
                    {props.comboName + ' - ' +tour.name}
                  </div>
                  <div className="text-xs font-medium">
                    {tour.region}
                  </div>
                  <div className="flex items-center text-sm font-medium text-gray">
                    <Icon className="mr-2">local_activity</Icon> {tour.quantityAdult + tour.quantityKid} de {tour.availables_cupos} disponíveis em {tour.date}
                  </div>
                </div>
              </div>
              <TableRow>
                <TableCell className="font-bold text-blue">Passageiros</TableCell>
                <TableCell className="font-bold text-blue">Quantidade</TableCell>
                <TableCell className="font-bold text-blue">Valor</TableCell>
                <TableCell className="font-bold text-blue">TX operacional Parceiro</TableCell>
                <TableCell className="font-bold text-blue">TX operacional Venda</TableCell>
                <TableCell className="font-bold text-right text-blue">Total</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell className="text-blue" component="th" scope="row">
                  Adultos
                </TableCell>
                <TableCell className="text-blue" component="th" scope="row">
                  {tour.quantityAdult > 0 &&
                    <Icon
                      className="mr-2 text-sm cursor-pointer"
                      onClick={() => props.setQuantities(idx, tour.quantityAdult, 'quantityAdult', 'remove')}
                    >
                      remove
                    </Icon>
                  }
                  {tour.quantityAdult}
                  {(tour.quantityAdult + tour.quantityKid) < tour.availables_cupos &&
                    <Icon
                      className="ml-2 text-sm cursor-pointer"
                      onClick={() => props.setQuantities(idx, tour.quantityAdult, 'quantityAdult', 'add')}
                    >
                      add
                    </Icon>
                  }
                </TableCell>
                <TableCell className="text-blue" component="th" scope="row">
                  <NumberFormat
                    value={tour.price}
                    displayType={'text'}
                    decimalSeparator={","}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    decimalPrecision={2}
                    isNumericString
                    prefix={`${process.env.REACT_APP_CURRENCY} `}
                    renderText={(value) => <span className="flex items-center">{value}</span>}
                  />
                </TableCell>
                <TableCell className="text-blue" component="th" scope="row">
                  <NumberFormat
                    value={tour.operational_cost}
                    displayType={'text'}
                    decimalSeparator={","}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    decimalPrecision={2}
                    thousandSeparator={"."}
                    isNumericString
                    prefix={`${process.env.REACT_APP_CURRENCY} `}
                    renderText={(value) => <span className="flex items-center">{value}</span>}
                  />
                </TableCell>
                <TableCell className="text-blue" component="th" scope="row">
                  <NumberFormat
                    value={tour.operational_cost_sale}
                    displayType={'text'}
                    decimalSeparator={","}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    decimalPrecision={2}
                    thousandSeparator={"."}
                    isNumericString
                    prefix={`${process.env.REACT_APP_CURRENCY} `}
                    renderText={(value) => <span className="flex items-center">{value}</span>}
                  />
                </TableCell>
                <TableCell className="text-blue" component="th" scope="row">
                  <NumberFormat
                    value={tour.totalAdult - tour.totalAdult * (Number(props.discount) / 100)}
                    displayType={'text'}
                    decimalSeparator={","}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    decimalPrecision={2}
                    thousandSeparator={"."}
                    isNumericString
                    prefix={`${process.env.REACT_APP_CURRENCY} `}
                    renderText={(value) => <span className="flex justify-end">{value}</span>}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="text-blue" component="th" scope="row">
                  Crianças
                </TableCell>
                <TableCell className="text-blue" component="th" scope="row">
                  {tour.quantityKid > 0 &&
                    <Icon
                      className="mr-2 text-sm cursor-pointer"
                      onClick={() => props.setQuantities(idx, tour.quantityKid, 'quantityKid', 'remove')}
                    >
                      remove
                    </Icon>
                  }
                  {tour.quantityKid}
                  {(tour.quantityAdult + tour.quantityKid) < tour.availables_cupos &&
                    <Icon
                      className="ml-2 text-sm cursor-pointer"
                      onClick={() => props.setQuantities(idx, tour.quantityKid, 'quantityKid', 'add')}
                    >
                      add
                    </Icon>
                  }
                </TableCell>
                <TableCell className="text-blue" component="th" scope="row">
                  <NumberFormat
                    value={tour.kids_price}
                    displayType={'text'}
                    decimalSeparator={","}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    decimalPrecision={2}
                    thousandSeparator={"."}
                    isNumericString
                    prefix={`${process.env.REACT_APP_CURRENCY} `}
                    renderText={(value) => <span className="flex items-center">{value}</span>}
                  />
                </TableCell>
                <TableCell className="text-blue" component="th" scope="row">
                  <NumberFormat
                    value={tour.kid_operational_cost}
                    displayType={'text'}
                    decimalSeparator={","}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    decimalPrecision={2}
                    thousandSeparator={"."}
                    isNumericString
                    prefix={`${process.env.REACT_APP_CURRENCY} `}
                    renderText={(value) => <span className="flex items-center">{value}</span>}
                  />
                </TableCell>
                <TableCell className="text-blue" component="th" scope="row">
                  <NumberFormat
                    value={tour.kid_operational_cost_sale}
                    displayType={'text'}
                    decimalSeparator={","}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    decimalPrecision={2}
                    thousandSeparator={"."}
                    isNumericString
                    prefix={`${process.env.REACT_APP_CURRENCY} `}
                    renderText={(value) => <span className="flex items-center">{value}</span>}
                  />
                </TableCell>
                <TableCell className="text-blue" component="th" scope="row">
                  <NumberFormat
                    value={tour.totalKid}
                    displayType={'text'}
                    decimalSeparator={","}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    decimalPrecision={2}
                    thousandSeparator={"."}
                    isNumericString
                    prefix={`${process.env.REACT_APP_CURRENCY} `}
                    renderText={(value) => <span className="flex justify-end">{value}</span>}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </>
        ))}
        <TableFooter>
          <TableRow>
            <TableCell className="text-base text-blue" component="th" scope="row">
              Subtotal
            </TableCell>
            <TableCell className="text-base text-blue"  colSpan={5}>
              <NumberFormat
                value={calcSubtotal()}
                displayType={'text'}
                decimalSeparator={","}
                decimalScale={2}
                fixedDecimalScale={true}
                decimalPrecision={2}
                thousandSeparator={"."}
                isNumericString
                prefix={`${process.env.REACT_APP_CURRENCY} `}
                renderText={(value) => <span className="flex justify-end">{value}</span>}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="text-base text-blue" component="th" scope="row">
              Descontos
            </TableCell>
            <TableCell className="text-base text-blue" colSpan={5}>
              <NumberFormat
                value={calcDiscountTotal()}
                displayType={'text'}
                decimalSeparator={","}
                decimalScale={2}
                fixedDecimalScale={true}
                decimalPrecision={2}
                thousandSeparator={"."}
                isNumericString
                prefix={`${process.env.REACT_APP_CURRENCY} `}
                renderText={(value) => <span className="flex justify-end">{value}</span>}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="text-lg font-medium text-blue" component="th" scope="row">
              Total
            </TableCell>
            <TableCell className="text-lg font-medium text-blue" colSpan={5}>
              <NumberFormat
                value={calcValueTotal()}
                displayType={'text'}
                decimalSeparator={","}
                decimalScale={2}
                fixedDecimalScale={true}
                decimalPrecision={2}
                thousandSeparator={"."}
                isNumericString
                prefix={`${process.env.REACT_APP_CURRENCY} `}
                renderText={(value) => <span className="flex justify-end">{value}</span>}
              />
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
    </div>
  </>
}

export default ContentStep2;
