import { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableFooter from '@material-ui/core/TableFooter';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Icon from '@material-ui/core/Icon';
import NumberFormat from 'react-number-format';
import DiscountInput from '@components/DiscountInput';
const useStyles = makeStyles({
  table: {
    width: '100%',
  },
  tableContainer: {
    boxShadow: 'none'
  }
});

const ContentStep2 = (props) => {
  const classes = useStyles();
  const [transfer, setTransfer] = useState({})
  const [indexTransfer, setIndexTransfer] = useState(null)

  const calcDiscountTotal = () => {
    const discount = Number(props.reservationTransfer.total) * (Number(props.reservationTransfer.discount) / 100)
    return discount
  }

  const calcValueTotal = () => {
    const discount = Number(props.reservationTransfer.total) * (Number(props.reservationTransfer.discount) / 100)
    return Number(props.reservationTransfer.total - discount)
  }

  const setFindTransfer = (transfer, index, quantity) => {
    if (quantity >= transfer.value_of && quantity <= transfer.value_to) {
      setIndexTransfer(index)
      const totalValue = (Number(transfer.value))
      props.changeValue({
        target: {
          name: 'total',
          value: totalValue
        }
      })
      return true
    }
  }
  const getTranferByQuantity = quantity => {
    const result = props.transferValues.find((transfer, idx) => setFindTransfer(transfer, idx, quantity))
    setTransfer(result)
  }

  const notDisableMinusButton = () => {
    return props.reservationTransfer.quantity > 0;
  }

  const notDisableAddButton = () => {
    return (props.reservationTransfer.quantity) < props.reservationTransfer.totalQuantity;
  }


  useEffect(() => {
    getTranferByQuantity(props.reservationTransfer.quantity);
  }, [props.reservationTransfer.quantity]);

  return <>
    <TableContainer className={classes.tableContainer} component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className="font-bold text-blue">Passageiros</TableCell>
            <TableCell className="font-bold text-blue">Valor</TableCell>
            <TableCell className="font-bold text-blue">TX operacional</TableCell>
            <TableCell className="font-bold text-blue">Desconto</TableCell>
            <TableCell className="font-bold text-right text-blue">Total</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell className="text-blue" component="th" scope="row">
              <Icon
                className={notDisableMinusButton() ? "mr-2 text-sm cursor-pointer" : "mr-2 text-sm"} style={{ color: !notDisableMinusButton() ? '#c9c9c9' : 'black' }}
                onClick={notDisableMinusButton() ? () => props.setQuantities(props.reservationTransfer.quantity, 'remove', indexTransfer) : () => { }}
              >
                remove
              </Icon>
              {props.reservationTransfer.quantity}
              <Icon
                className={notDisableAddButton() ? "ml-2 text-sm cursor-pointer" : "ml-2 text-sm"} style={{ color: !notDisableAddButton() ? '#c9c9c9' : 'black' }}
                onClick={notDisableAddButton() ? () => props.setQuantities(props.reservationTransfer.quantity, 'add', indexTransfer) : () => { }}
              >
                add
              </Icon>
            </TableCell>
            <TableCell className="text-blue" component="th" scope="row">
              <NumberFormat
                value={transfer.value}
                displayType={'text'}
                decimalSeparator={","}
                decimalScale={2}
                fixedDecimalScale={true}
                decimalPrecision={2}
                thousandSeparator={"."}
                isNumericString
                prefix={`${process.env.REACT_APP_CURRENCY} `}
                renderText={(value) => <span className="flex items-center">{value}</span>}
              />
            </TableCell>
            <TableCell className="text-blue" component="th" scope="row">
              <NumberFormat
                value={transfer.value_cost}
                displayType={'text'}
                decimalSeparator={","}
                decimalScale={2}
                fixedDecimalScale={true}
                decimalPrecision={2}
                thousandSeparator={"."}
                isNumericString
                prefix={`${process.env.REACT_APP_CURRENCY} `}
                renderText={(value) => <span className="flex items-center">{value}</span>}
              />
            </TableCell>
            <TableCell className="text-blue" component="th" scope="row">
              <DiscountInput
                name="discount"
                value={props.reservationTransfer.discount}
                onChange={(ev) => props.changeValue(ev)}
                discountLimit={transfer.margin_discount}
              />
            </TableCell>
            <TableCell className="text-blue" component="th" scope="row">
              <NumberFormat
                value={props.reservationTransfer.total}
                displayType={'text'}
                decimalSeparator={","}
                decimalScale={2}
                fixedDecimalScale={true}
                decimalPrecision={2}
                thousandSeparator={"."}
                isNumericString
                prefix={`${process.env.REACT_APP_CURRENCY} `}
                renderText={(value) => <span className="flex justify-end">{value}</span>}
              />
            </TableCell>
          </TableRow>
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell className="text-base text-blue" component="th" scope="row">
              Subtotal
            </TableCell>
            <TableCell className="text-base text-blue" colSpan={5}>
              <NumberFormat
                value={props.reservationTransfer.total}
                displayType={'text'}
                decimalSeparator={","}
                decimalScale={2}
                fixedDecimalScale={true}
                decimalPrecision={2}
                thousandSeparator={"."}
                isNumericString
                prefix={`${process.env.REACT_APP_CURRENCY} `}
                renderText={(value) => <span className="flex justify-end">{value}</span>}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="text-base text-blue" component="th" scope="row">
              Descontos
            </TableCell>
            <TableCell className="text-base text-blue" colSpan={5}>
              <NumberFormat
                value={calcDiscountTotal()}
                displayType={'text'}
                decimalSeparator={","}
                decimalScale={2}
                fixedDecimalScale={true}
                decimalPrecision={2}
                thousandSeparator={"."}
                isNumericString
                prefix={`${process.env.REACT_APP_CURRENCY} `}
                renderText={(value) => <span className="flex justify-end">{value}</span>}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="text-lg font-medium text-blue" component="th" scope="row">
              Total
            </TableCell>
            <TableCell className="text-lg font-medium text-blue" colSpan={5}>
              <NumberFormat
                value={calcValueTotal()}
                displayType={'text'}
                decimalSeparator={","}
                decimalScale={2}
                fixedDecimalScale={true}
                decimalPrecision={2}
                thousandSeparator={"."}
                isNumericString
                prefix={`${process.env.REACT_APP_CURRENCY} `}
                renderText={(value) => <span className="flex justify-end">{value}</span>}
              />
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  </>
}

export default ContentStep2;
