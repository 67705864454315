import TextField from '@components/TextField';
import { apiClient } from '@services/api';
import Button from '@components/Button';
import Select from '@components/Select';
import Table from '@components/Table';
import { useHistory } from "react-router-dom";
import { useState, useEffect } from 'react';
import { format, isValid } from 'date-fns';
import NumberFormat from 'react-number-format';
import Alert from '@material-ui/lab/Alert';

const EditToursTable = (props) => {
  document.title = `Lista de passeios - ${process.env.REACT_APP_NAME}`
  const history = useHistory();
  let [rows] = useState([]);
  const [q, setQ] = useState(null)
  const [cupos, setCupos] = useState(0)
  const [from_date, setFromDate] = useState(null)
  const [to_date, setToDate] = useState(null)
  let [region, setRegion] = useState("all");
  let [regionsData, setRegionsData] = useState([]);
  let [filterStatus, setFilterStatus] = useState([]);
  let urlRegions = '/regions';
  const [isError, setError] = useState(false);

  const changeFromDate = (date) => {
    setFromDate(isValid(date) === true ? format(date, "yyyy-MM-dd") : null)
  }

  const changeToDate = (date) => {
    setToDate(isValid(date) === true ? format(date, "yyyy-MM-dd") : null)
  }

  const getRegionData = url => {
    apiClient.httpInstance.get(url).then(response => {
      if (response.status === 200) {
        let regions = response.data.sort(function (a, b) {
          return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
        })

        setRegionsData(regions);
      }
    });
  }

  const getRegionById = id => {
    return apiClient
      .httpInstance.get(`/regions/${id}`)
      .then(response => {
        if (response.status === 200 || response.status === 201) {
          return response.data.name
        }
      })
      .catch(error => {
        if (error) {
          return 'Sem região'
        }
      });
  }

  const getArrayWithRegionName = async (array) => {
    const value = array.map(async (obj) => {
      return {
        ...obj,
        regionName: !Number(obj.region) ? obj.region : await getRegionById(obj.region)
      }
    })
    return Promise.all(value)
  }

  const handle = (e, row) => {
    var travels = [];
    var reservations = [];
    var reservationsToAdd = [];
    while (reservations.length == 0 || reservations == undefined || reservations == null) {
      reservations = props.getReservations();
      reservationsToAdd = props.getReservationsToAdd();
    }

    var index = reservations.findIndex((f) => { return f.travel_id == row.id && f.combo_id == null });
    var indexNewReservations = reservationsToAdd.findIndex((f) => { return f.travel_id == row.id && f.combo_id == null });

    if (index != -1 || indexNewReservations != -1) {
      setTimeout(() => { setError(true) }, 4000);
      setError(false);
      return;
    }

    var item = {
      id: 0,
      travel_id: row.id,
      travel_type: null,
      transfer_type: null,
      customer_email: "",
      cupos: row.availables_cupos,
      operational_cost: row.operational_cost,
      is_group: true,
      tour_date: null,
      adults_total: 0,
      adults_total_amount: 0,
      adults_operational_cost: row.operational_cost,
      adults_discount: 0,
      kids_total: 0,
      kids_total_amount: 0,
      kids_operational_cost: row.kid_operational_cost,
      kids_discount: 0,
      discount_total_amount: 0,
      combo_id: 0,
      total_amount: 0,
      commission: null,
      discount: null,
      flight_code: null,
      flight_time: null,
      fixed_commission_dolars: null,
      fixed_commission_pesos: null,
      margin_discount: null,
      mode_transfer: null,
      modality: null,
      quantity: 0,
      status: row.status == true ? "1" : "0",
      total: 0,
      value: 0,
      value_cost: null,
      value_pesos: null,
      value_pesos_cost: null,
      value_to: null,
      value_of: null,
      pre_sale_id: 0,
      status_discount_approve: null,
      justify_discount: null,
      discount_total_sale: 0,
      price: row.price,
      travel_name: row.name,
      region_id: row.region,
      banner: row.banner,
      type: "tour"
    }

    props.pushBudgets(item, false, true, false);
  }

  useEffect(() => {
    getRegionData(urlRegions);
  }, [urlRegions]);

  return <>
    <div className="px-7 pt-6">
      <div className="flex items-center justify-between mt-2 text-2xl text-blue">
        <span>Passeios</span>
      </div>
      <div className="p-4 mt-4 bg-white border-1" style={{ borderRadius: '10px 10px 0px 0px' }}>
        <div className="grid items-center grid-cols-5 gap-4">
          <div>
            <TextField
              className="inputRounded"
              placeholder="Busque por um passeio"
              variant="outlined"
              type="text"
              name="q"
              value={q}
              onChange={(e) => setQ(e.target.value)}
              required
            />
          </div>
          <div>
            <Select
              label="Filtrar por Região"
              defaultValue="all"
              gender="f"
              onChange={(e) => setRegion(e.target.value)}
              items={{
                field: {
                  value: 'id',
                  text: 'name'
                },
                data: regionsData
              }}
            ></Select>
          </div>
          <div className="flex items-center justify-end col-start-5 col-end-6">
            <Select
              label="Status"
              defaultValue="all"
              onChange={(e) => console.log(e.target.value)}
              items={{
                field: {
                  value: 'id',
                  text: 'name'
                },
                data: filterStatus
              }}
            ></Select>
          </div>
        </div>
      </div>
      {isError && (
        <Alert className="mb-5" variant="filled" severity="error">
          {'Já existe esse passeio no orçamento.'}
        </Alert>
      )}
      <Table
        path="/tours?reservations=true"
        params={{
          q, region, from_date, to_date, cupos
        }}
        onRowClick={null}
        columns={[
          {
            headerName: 'Passeio',
            headerClassName: 'super-app-theme--header',
            field: 'name',
            flex: 1,
          },
          {
            headerName: 'Pax',
            headerClassName: 'super-app-theme--header',
            field: 'price',
            flex: 1,
            renderCell: (params) => {
              const row = params.row;
              return (
                <NumberFormat
                  value={Number(row.price)}
                  displayType={'text'}
                  decimalSeparator={","}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  decimalPrecision={2}
                  thousandSeparator={"."}
                  isNumericString
                  prefix={`${process.env.REACT_APP_CURRENCY} `}
                  renderText={(value) => <div className="flex items-center pr-4 space-x-5">{value}</div>}
                />);
            }
          },
          {
            headerName: 'Pax criança',
            headerClassName: 'super-app-theme--header',
            field: 'kids_price',
            flex: 1,
            renderCell: (params) => {
              const row = params.row;
              return (
                <NumberFormat
                  value={row.kids_price}
                  displayType={'text'}
                  decimalSeparator={","}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  decimalPrecision={2}
                  thousandSeparator={"."}
                  isNumericString
                  prefix={`${process.env.REACT_APP_CURRENCY} `}
                  renderText={(value) => <div className="flex items-center pr-4 space-x-5">{value}</div>}
                />);
            }
          },
          {
            headerName: ' ',
            field: 'actions',
            headerAlign: 'right',
            align: 'right',
            headerClassName: 'super-app-theme--header',
            flex: 1,
            renderCell: (params) => {
              const row = params.row;
              const link = `passeios/${row.id}/comprar`
              return (<div className="flex items-center pr-4 mb-3">
                <Button
                  textcolor="#212121"
                  padding="6px 15px"
                  width="200"
                  variant="outlined"
                  onClick={(e) => handle(e, row)}
                  bgColor="transparent"
                >
                  Adicionar Ao Orcamento
                </Button>
              </div>);
            }
          },
        ]}
        rows={rows}
      />
    </div>
  </>
}

export default EditToursTable;