
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import EditTour from '../product/tour/EditTour';
import EditTransfer from '../product/transfer/EditTransfer';
import EditCombo from '../product/combo/EditCombo';
import Button from '@components/Button';
import { useEffect, useState } from 'react';
import { TableFooter } from '@material-ui/core';
import BreadCrumbs from '@components/Breadcrumbs';
import Icon from '@material-ui/core/Icon';
import { apiClient } from '@services/api';
import { makeStyles } from '@material-ui/core/styles';
const UpateTableBody = (props) => {

    const useStyles = makeStyles({
        tableContainer: {
            boxShadow: 'none',
        },
        table: {
            minWidth: 650,
        },
        borderNone: {
            border: 'none'
        }
    });

    const url = `presales/${props.presales_id}`
    const [regionsTravel, setRegionsTravel] = useState([]);
    const [reservationsChanged, setReservationsChanged] = useState(true)

    const removeReservation = (travel_id, isCombo = false, isTour = false) => {
        let reservationToRemove = props.reservationsToRemove;
        if (isCombo) {
            var index = props.reservations.map(item => item.combo_id).indexOf();
            while (props.reservations.findIndex((r) => r.combo_id == travel_id) != -1) {
                var index = props.reservations.findIndex((r) => r.combo_id == travel_id);
                var indexToShow = props.reservationsToShow.findIndex((r) => r.combo_id == travel_id);
                var indexNewReservations = props.reservationsToAdd.findInde((r) => r.combo_id == travel_id);

                if (indexToShow != -1) {
                    props.reservationsToShow.splice(indexToShow, 1);
                }

                if (index != -1) {
                    reservationToRemove.push(props.reservations[index]);
                    props.reservations.splice(index, 1);
                    props.setReservationsToRemove(reservationToRemove);
                }

                if (indexNewReservations != -1) {
                    props.reservationsToAdd.splice(indexNewReservations, indexNewReservations);
                }
            }
        }
        if (isTour) {
            var index = props.reservations.findIndex((r) => r.travel_id == travel_id && r.combo_id == null);
            var indexToShow = props.reservationsToShow.findIndex((r) => r.travel_id == travel_id && r.combo_id == null);
            var indexNewReservations = props.reservationsToAdd.findIndex((r) => r.travel_id == travel_id && r.combo_id == null);

            if (index != -1) {
                reservationToRemove.push(props.reservations[index]);
                props.reservations.splice(index, 1);
            }

            if (indexToShow != -1) {
                props.reservationsToShow.splice(indexToShow, 1);
            }

            if (indexNewReservations != -1) {
                props.reservationsToAdd.splice(indexNewReservations, 1);
            }
        }
        else {
            var index = props.reservations.findIndex((r) => r.travel_id == travel_id);
            var indexToShow = props.reservationsToShow.findIndex((r) => r.travel_id == travel_id);
            var indexNewReservations = props.reservationsToShow.findIndex((r) => r.travel_id == travel_id);

            if (index != -1) {
                reservationToRemove.push(props.reservations[index]);
                props.reservations.splice(index, 1);
            }

            if (indexToShow != -1) {
                props.reservationsToShow.splice(indexToShow, 1);
            }

            if (indexNewReservations != -1) {
                props.reservationsToAdd.splice(indexNewReservations, 1);
            }
        }

        props.setReservationsToRemove(reservationToRemove);
        props.setReservations(props.reservations);
        props.setReservationsToShow(props.reservationsToShow);
        props.setReservationsToAdd(props.reservationsToAdd);
        setReservationsChanged(!reservationsChanged);
    }

    const TableItem = (item) => {
        if (item?.item.type && item?.item.type == 'tour' && (!item?.item.combo_id || !item?.item.combo_id == null)) {
            return <EditTour  {...props} travelInformation={item.item} removeReservation={removeReservation} />
        }

        if (item?.item.type && item?.item.type == 'transfer') {
            return <EditTransfer {...props} travelInformation={item.item} removeReservation={removeReservation} />
        }

        if (item?.item.combo_id && item?.item.combo_id != null) {
            var comboItems = props.reservations.filter((r) => {
                return r.combo_id === item.item.combo_id;
            });
            return <EditCombo {...props} travelInformation={item.item} comboTravels={comboItems} removeReservation={removeReservation} />
        }
    }

    const setQuantities = (index, value, action, tour) => {
        let valueQuantity = action === 'add' ? (Number(value) + 1) : (Number(value) - 1)
        //getReservations(url)
        let lastReservations = props.reservations

        lastReservations[index].adults_total = valueQuantity

        //Atualiza a reserva para o update
        props.setReservations(lastReservations)
    }

    const setQuantitiesTour = (index, value, type, action, tour) => {
        let valueQuantity = action === 'add' ? (Number(value) + 1) : (Number(value) - 1)
        //getReservations(url)
        let lastReservations = props.reservations

        if (type == 'adults') {
            lastReservations[index].adults_total = valueQuantity
        }
        if (type == 'kids') {
            lastReservations[index].kids_total = valueQuantity
        }

        props.setReservations(lastReservations)
    }

    const changeReserve = (ev) => {
        let value = ev.target.value
        if (ev.target.name === "havePartner") {
            value = ev.target.value === "true" ? true : false;
        }
        var reserve = props.reserve
        props.setReserve({
            ...reserve,
            [ev.target.name]: value
        })
    }

    const getReserveById = url => {
        apiClient.httpInstance.get(url)
            .then(response => {
                if (response.status === 200) {
                    props.setClient({
                        customer_id: response.data.customer_id,
                        addresses: response.data.customer_addresses,
                        name: response.data.name,
                        email: response.data.email,
                        phone: response.data.phone,
                        document: response.data.document,
                        hotel_name: response.data.hotel,
                        hotel_address: response.data.hotel_address,
                        room_number: response.data.room_number,
                        customer_language: response.data.language,
                        observations: response.data.observations,
                    })
                    props.setReserve({
                        id: response.data.id,
                        total: response.data.total,
                        user_id: response.data.user_id,
                        seller_name: response.data.seller_name,
                        seller_email: response.data.seller_email,
                        proofs: [],
                        discount_total_sale: response.data.discount_total_sale,
                    })
                    props.setIsEditing(true)
                }
            }
            );
    }

    const getReservations = url => {
        apiClient.httpInstance.get(`${url}/budgets`).then(response => {
            if (response.status === 200) {
                props.setReservations(response.data);

                var reservationsFiltered = filterComboReservation(response.data);
                props.setReservationsToShow(reservationsFiltered);

                response.data.map((element) => {
                    //Recolhe ids das regiões para lista de endereços do cliente
                    if (element && element.region_id !== undefined) {
                        if (!regionsTravel.includes(element.region_id)) {
                            regionsTravel.push(element.region_id)
                        }
                    }
                })
            }
        });
    }

    const filterComboReservation = (reservationsToFilter) => {
        var filteredList = [];
        reservationsToFilter.map((r) => {
            if (r.combo_id != null) {
                if (filteredList.findIndex((f) => { return f.combo_id === r.combo_id }) === -1) {
                    filteredList.push(r);
                }
            }
            else {
                filteredList.push(r);
            }
        });

        return filteredList
    }

    useEffect(() => {
        getReserveById(url);
        getReservations(url)
    }, [url]);


    const classes = useStyles();

    return <>

        <TableContainer className={classes.tableContainer} component={Paper}>
            <Table className={classes.table} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell className="flex items-center justify-between font-bold leading-5 text-blue border-0">
                            Minhas reservas
                            <div className="flex justify-end items-center">
                                <Button
                                    margintop='0px'
                                    textcolor="#fff"
                                    padding="6px 15px"
                                    width="260px"
                                    onClick={() => { props.setOpen(!props.open) }}
                                    customcolor={props.theme.color}
                                >
                                    <Icon className="mr-2">
                                        add
                                    </Icon>
                                    Adicionar reserva
                                </Button>
                            </div>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.reservationsToShow.map((itemMap) => (
                        <TableRow className="font-bold leading-5 text-blue border-0">
                            {/* <div>{itemMap}</div> */}
                            <Paper className='p-2.5' variant="outlined">
                                <TableItem item={itemMap} removeReservation={removeReservation} />
                            </Paper>
                        </TableRow>
                    ))}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TableCell className="flex items-center justify-end font-bold leading-5 text-blue border-0">
                            <Button
                                margintop='0px'
                                textcolor="#fff"
                                padding="6px 15px"
                                width="260px"
                                onClick={() => { props.setOpen(!props.open) }}
                                customcolor={props.theme.color}
                            >
                                <Icon className="mr-2">
                                    add
                                </Icon>
                                Adicionar reserva
                            </Button>
                        </TableCell>
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer>
    </>
}

export default UpateTableBody;